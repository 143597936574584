<template>
  <section class="app-ecommerce-details">
    <!-- Alert: No item found -->

    <b-overlay :show="formShow" rounded="sm" no-fade>
      <b-row>
        <b-col cols="12" xl="2" md="3" sm="12" xs="12">
          <b-card no-body>
            <b-card-body>
              <b-row>
                <!-- image upload -->
                <b-col cols="12" xl="12" md="12" sm="3" xs="12" class="d-flex justify-content-center align-items-center">
                  <b-img-lazy rounded thumbnail v-bind="mainProps" style="width: 100%" ref="refPreviewEl" :src="imageUrl" />
                </b-col>
                <b-col cols="12" xl="12" md="12" sm="9" xs="12" class="justify-content-center align-items-center text-center">
                  <h2 class="ml-1 toCapitalFirst mt-3">{{ userInfo.name + ' ' + userInfo.surname }}</h2>

                  <table class="modern-table mt-1 justify-content-center align-items-center text-center w-100">
                    <tbody>
                      <tr></tr>

                      <tr class="mb-1">
                        <td v-if="userInfo.team === 'Group Tailor Made'" class="emoji">
                          <b-badge pill variant="dark">{{ userInfo.team }} </b-badge>
                        </td>
                        <td v-else-if="userInfo.team === 'Tailor Made Excel'" class="emoji">
                          <b-badge pill variant="success">{{ userInfo.team }} </b-badge>
                        </td>
                        <td v-else class="emoji">
                          <b-badge pill variant="primary">{{ userInfo.team }} </b-badge>
                        </td>
                      </tr>

                      <tr class="mb-1">
                        <th>📝 Employment Type</th>
                      </tr>

                      <tr class="mb-1">
                        <td class="emoji">{{ userInfo.employment }}</td>
                      </tr>
                      <tr>
                        <th>📅 Hired on</th>
                      </tr>
                      <tr class="mb-1">
                        <td class="emoji">{{ userInfo.hired_on }}</td>
                      </tr>
                      <tr>
                        <th>💼 Position</th>
                      </tr>
                      <tr class="mb-1">
                        <td class="emoji">{{ userInfo.role }}</td>
                      </tr>
                      <tr>
                        <th>🚩 Location</th>
                      </tr>
                      <tr class="mb-1">
                        <td class="emoji">{{ userInfo.country }}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12" xl="10" md="9" sm="12" xs="12">
          <b-card>
            <b-card-body>
              <b-row>
                <b-col cols="12">
                  <!-- <b-row class="justify-content-end">
                    <div>
                      <b-button v-show="$Can('employees_activate_btn')" class="mr-1 btn btn-sm" v-if="userInfo.status != 'active'" @click="activateEmployee" variant="success">
                        Activate
                      </b-button>

                      <b-button v-show="$Can('employees_remove_btn')" class="mr-1 btn btn-sm" v-if="userInfo.status != 'deleted'" @click="removeEmployee" variant="danger">
                        Remove
                      </b-button>

                      <b-button v-show="$Can('employees_terminate_btn')" class="mr-1 btn btn-sm" v-if="userInfo.status != 'passive'" @click="terminateEmployee" variant="warning">
                        Deactivate
                      </b-button>

                      <b-button v-show="$Can('employees_edit_btn')" class="mr-1 btn btn-sm" @click="editEmployee" variant="primary">
                        Edit
                      </b-button>
                    </div>
                  </b-row> -->

                  <b-row class="justify-content-end">
                    <div>
                      <b-button class="mr-1 btn btn-sm" v-if="userInfo.status != 'active'" @click="activateEmployee" variant="success">
                        Activate
                      </b-button>

                      <!-- <b-button  class="mr-1 btn btn-sm" v-if="userInfo.status != 'deleted'" @click="removeEmployee" variant="danger">
                        Remove
                      </b-button> -->

                      <b-button class="mr-1 btn btn-sm" v-if="userInfo.status != 'passive' && $Can('user_deactivate')" @click="terminateEmployee" variant="warning">
                        Deactivate
                      </b-button>

                      <b-button class="mr-1 btn btn-sm" @click="editEmployee" variant="primary">
                        Edit
                      </b-button>
                    </div>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>
            <b-row>
              <b-col cols="12">
                <b-tabs horizontal nav-wrapper-class="nav-horizontal" pills class="tab-class">
                  <b-tab active title="Personal" v-if="$Can('user_personal_tab')">
                    <b-card-body class="mt-0 pt-0">
                      <!-- <h3>General Information</h3> -->
                      <b-row>
                        <table class="table">
                          <tbody class="text-left">
                            <tr>
                              <td class="item-price  w-25">First name</td>
                              <td class="w-75">{{ userInfo.name }}</td>
                            </tr>

                            <tr>
                              <td class="item-price ">Last name</td>
                              <td>{{ userInfo.surname }}</td>
                            </tr>

                            <tr>
                              <td class="item-price ">Phone</td>
                              <td>{{ userInfo.phone }}</td>
                            </tr>

                            <tr>
                              <td class="item-price ">Email</td>
                              <td>{{ userInfo.email }}</td>
                            </tr>

                            <tr>
                              <td class="item-price ">Role</td>
                              <td>{{ userInfo.role }}</td>
                            </tr>

                            <tr>
                              <td class="item-price ">Date Of Birth</td>
                              <td>{{ userInfo.birthday }}</td>
                            </tr>

                            <tr>
                              <td class="item-price ">Address</td>
                              <td>{{ userInfo.address }}</td>
                            </tr>

                            <tr>
                              <td class="item-price ">Post Code</td>
                              <td>{{ userInfo.postCode }}</td>
                            </tr>

                            <tr>
                              <td class="item-price ">City</td>
                              <td>{{ userInfo.city }}</td>
                            </tr>

                            <tr>
                              <td class="item-price ">Country</td>
                              <td>{{ userInfo.country }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </b-row>
                    </b-card-body>
                  </b-tab>

                  <b-tab title="Documents" v-if="$Can('user_documents_tab')">
                    <b-card-body class="mt-0 pt-0">
                      <b-row>
                        <h4 class="btn-icon mr-auto  mt-1 mb-1" variant="primary">
                          Onboarding Checklist
                        </h4>
                      </b-row>

                      <b-row>
                        <div class="w-100">
                          <div class="todo-header w-100">
                            <div class="header-cell w-10"><span class="checkbox-icon"></span></div>
                            <div class="header-cell w-90">Name</div>
                          </div>

                          <div v-for="(task, index) in userInfo.onboarding" :key="index">
                            <div class="task-content">
                              <div class="todo-cell w-10 d-flex justify-content-center">
                                <i v-if="task.type == 'Document'" :class="['fa-solid', 'fa-caret-' + (task.isExpanded ? 'down' : 'right'), 'mr-1 text-dark']" style="font-size: 1rem; cursor:pointer" @click="toggleExpand(task)"></i>
                                <i v-else :class="['fa-solid', 'fa-caret-right', 'mr-1']" style="font-size: 1rem; cursor:pointer; visibility: hidden"></i>
                                <div class="custom-checkbox">
                                  <span class="checkbox-icon" :class="{ checked: task.isCompleted }" @click="updateOnboardingCompleted(task)"></span>
                                </div>
                              </div>

                              <div class="todo-cell w-90 hover-background" style="border-left:none; cursor: pointer; transition: background-color 0.2s;">
                                <div class="task-name">
                                  <div class="w-100 ml-2">{{ task.name }}</div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div v-if="task.isExpanded" class="subtask-content">
                                <div class="todo-cell w-100 hover-background justify-content-center align-items-center" style=" cursor: pointer; transition: background-color 0.2s;" @click="handleTaskClick(subtask)">
                                  <div class="task-name" v-if="task.fileLink != null">
                                    <div class="ml-5 mr-5">
                                      <div class="d-flex justify-content-between align-items-center">
                                        <div v-if="task.fileStatus == 'A'" class="d-flex justify-content-between align-items-center">
                                          <b-img src="@/assets/images/file.png" @click="fileEdit(task)" class="mr-50 " style="cursor:pointer" width="25px" />
                                          <div class="user-page-info pr-1" style="width: 100%">
                                            <span @click="fileEdit(task)" style="cursor:pointer; font-size:1.1em" class="mb-2">{{ task.fileName }} </span>
                                          </div>

                                          <!-- <b-button  variant="primary" @click="invoiceFile(task)" class="btn-icon ml-auto mr-1" style="padding: 1% 1%;" size="sm">
                          Invoice
                        </b-button> -->

                                          <b-button variant="primary" target="_blank" :href="fileURL + task.fileLink" style="cursor:pointer;    margin-right:2px" class="btn-icon ml-auto mr-1" size="sm">
                                            <feather-icon icon="DownloadIcon" />
                                          </b-button>

                                          <b-button variant="danger" v-if="$Can('user_documents_remove')" @click="deleteFile(task)" class="btn-icon ml-auto mr-1" style="" size="sm">
                                            <feather-icon icon="XIcon" />
                                          </b-button>
                                        </div>

                                        <div v-else class="d-flex justify-content-between align-items-center">
                                          <b-img src="@/assets/images/url.png" @click="linkEdit(task)" class="mr-50 " style="cursor:pointer" width="25px" />
                                          <div class="user-page-info pr-1" style="width: 100%">
                                            <span @click="linkEdit(task)" style="cursor:pointer; font-size:1.1em">{{ task.fileName }} </span>
                                          </div>
                                          <b-button variant="primary" target="_blank" :href="'https://' + task.fileLink" style="cursor:alias;  margin-right:2px" class="btn-icon ml-auto mr-1" size="sm" v-b-tooltip.hover title="Go to url adress">
                                            <feather-icon icon="ExternalLinkIcon" />
                                          </b-button>

                                          <b-button variant="danger" v-if="$Can('user_documents_remove')" @click="deleteFile(task)" class="btn-icon ml-auto mr-1" style="" size="sm">
                                            <feather-icon icon="XIcon" />
                                          </b-button>
                                        </div>
                                        <hr />

                                        <!-- <b-img src="@/assets/images/file.png" @click="fileEdit(task)" class="mr-50" style="cursor:pointer" width="25px" />
                                <div class="user-page-info pr-1" style="width: 100%">
                                  <span @click="fileEdit(task)" style="cursor:pointer; font-size:1em">{{ task.fileName }} </span>
                                </div>

                                

                                <b-button variant="primary" target="_blank" :href="fileURL + task.fileLink" style="cursor:pointer;    margin-right:2px" class="btn-icon ml-auto mr-1" size="sm">
                                  <feather-icon icon="DownloadIcon" />
                                </b-button>

                                <b-button variant="danger" @click="deleteFile(task)" class="btn-icon ml-auto mr-1" style="" size="sm">
                                  <feather-icon icon="XIcon" />
                                </b-button>
                              </div> -->

                                        <hr />
                                      </div>
                                    </div>
                                  </div>
                                  <div v-else>
                                    <div class="d-flex">
                                      <b-button size="sm" @click="addNewFile(task)" variant="primary">
                                        Add New File
                                      </b-button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-row>
                    </b-card-body>

                    <b-card-body class="mt-0 pt-0">
                      <b-row>
                        <h4 class="btn-icon mr-auto  mt-1 mb-1" variant="primary">
                          Additional Files
                        </h4>

                        <b-button class="btn-icon ml-auto  mt-1 mb-1" @click="addNewFile()" variant="primary">
                          Add New File
                        </b-button>

                        <table class=" table  table-bordered table-hover table-sm ">
                          <b-col cols="12" v-if="userInfo.id != null">
                            <!-- user suggestion  -->
                            <div v-for="(data, index) in files" v-if="data.onboarding_id == null" :key="index" class="ml-5 mr-5 mt-2 mb-2">
                              <div v-if="data.status == 'A'" class="d-flex justify-content-between align-items-center">
                                <b-img src="@/assets/images/file.png" @click="fileEdit(data)" class="mr-50 " style="cursor:pointer" width="30px" />
                                <div class="user-page-info pr-1" style="width: 100%">
                                  <span @click="fileEdit(data)" style="cursor:pointer; font-size:1.1em" class="mb-2">{{ data.fileName }} </span>
                                </div>

                                <!-- <b-button  variant="primary" @click="invoiceFile(data)" class="btn-icon ml-auto mr-1" style="padding: 1% 1%;" size="sm">
                          Invoice
                        </b-button> -->

                                <b-button variant="primary" target="_blank" :href="fileURL + data.fileLink" style="cursor:pointer;    margin-right:2px" class="btn-icon ml-auto mr-1" size="sm">
                                  <feather-icon icon="DownloadIcon" />
                                </b-button>

                                <b-button variant="danger" v-if="$Can('user_documents_remove')" @click="deleteFile(data)" class="btn-icon ml-auto mr-1" style="" size="sm">
                                  <feather-icon icon="XIcon" />
                                </b-button>
                              </div>

                              <div v-else class="d-flex justify-content-between align-items-center">
                                <b-img src="@/assets/images/url.png" @click="linkEdit(data)" class="mr-50 " style="cursor:pointer" width="30px" />
                                <div class="user-page-info pr-1" style="width: 100%">
                                  <span @click="linkEdit(data)" style="cursor:pointer; font-size:1.1em">{{ data.fileName }} </span>
                                </div>
                                <b-button variant="primary" target="_blank" :href="'https://' + data.fileLink" style="cursor:alias;  margin-right:2px" class="btn-icon ml-auto mr-1" size="sm" v-b-tooltip.hover title="Go to url adress">
                                  <feather-icon icon="ExternalLinkIcon" />
                                </b-button>

                                <b-button variant="danger" v-if="$Can('user_documents_remove')" @click="deleteFile(data)" class="btn-icon ml-auto mr-1" style="" size="sm">
                                  <feather-icon icon="XIcon" />
                                </b-button>
                              </div>
                              <hr />
                            </div>

                            <div v-if="files.length == 0" class="d-flex justify-content-center align-items-center mt-1 mb-1">
                              No Files
                            </div>
                          </b-col>
                        </table>
                      </b-row>
                    </b-card-body>
                  </b-tab>

                  <b-tab title="Time Off" v-if="$Can('user_timeoff_tab')">
                    <b-form>
                      <b-row>
                        <b-col cols="4" xl="4" lg="4" md="6" sm="12" xs="12">
                          <b-card class="mx-1 my-1 d-flex justify-content-center align-items-center" style="border:1px solid gray;">
                            <div class="text-center">
                              <feather-icon icon="CalendarIcon" size="40" class="mb-2" />
                              <h5>Annual Leave</h5>
                            </div>
                            <p class="text-center">Used: {{ annualLeave }} days</p>
                            <b-button variant="primary" class="btn btn-sm" @click="timeOffAdd('Annual Leave')">Record Time Off</b-button>
                          </b-card>
                        </b-col>
                        <b-col cols="4" xl="4" lg="4" md="6" sm="12" xs="12">
                          <b-card class="mx-1 my-1 d-flex justify-content-center align-items-center" style="border:1px solid gray;">
                            <div class="text-center">
                              <feather-icon icon="ActivityIcon" size="40" class="mb-2" />
                              <h5>Sick Leave</h5>
                            </div>
                            <p class="text-center">Used: {{ sickLeave }} days</p>
                            <b-button variant="primary" class="btn btn-sm" @click="timeOffAdd('Sick Leave')">Record Time Off</b-button>
                          </b-card>
                        </b-col>
                        <b-col cols="4" xl="4" lg="4" md="6" sm="12" xs="12">
                          <b-card class="mx-1 my-1 d-flex justify-content-center align-items-center" style="border:1px solid gray;">
                            <div class="text-center">
                              <feather-icon icon="DollarSignIcon" size="40" class="mb-2" />
                              <h5>Unpaid Leave</h5>
                            </div>
                            <p class="text-center">Used: {{ unpaidLeave }} days</p>
                            <b-button variant="primary" class="btn btn-sm" @click="timeOffAdd('Unpaid Leave')">Record Time Off</b-button>
                          </b-card>
                        </b-col>
                      </b-row>
                    </b-form>
                    <b-row v-if="userInfo.id" class="mt-2">
                      <b-col cols="12">
                        <b-table striped hover ref="refUserListTable" class="position-relative" :items="timeOffData" filter-debounce="250" responsive :small="true" head-variant="dark" :fields="tableColumns" :current-page="currentPage" :per-page="5" show-empty empty-text="No matching records found">
                          <template #cell(date)="data"> {{ data.value }} </template>

                          <template #cell(type)="data">
                            <b-badge pill :variant="`light-success`" class="text-capitalize">
                              {{ data.value }}
                            </b-badge>
                          </template>

                          <template #cell(status)="data">
                            <b-badge v-if="data.value == 'A'" pill :variant="`light-secondary`" class="text-capitalize">
                              Pending
                            </b-badge>

                            <b-badge v-if="data.value == 'B'" pill :variant="`light-success`" class="text-capitalize">
                              Approved
                            </b-badge>

                            <b-badge v-if="data.value == 'C'" pill :variant="`light-danger`" class="text-capitalize">
                              Refused
                            </b-badge>
                          </template>

                          <template #cell(actions)="data">
                            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                              <template #button-content>
                                <feather-icon icon="MoreVerticalIcon" size="14" class="align-middle text-body" />
                              </template>

                              <b-dropdown-item v-if="$Can('dashboard_pending_timeoff')" @click="approveTimeOff(data)">
                                <feather-icon icon="CheckIcon" />
                                <span class="align-middle ml-50">Approve</span>
                              </b-dropdown-item>

                              <b-dropdown-item v-if="$Can('dashboard_pending_timeoff')" @click="refuseTimeOff(data)">
                                <feather-icon icon="XIcon" />
                                <span class="align-middle ml-50">Refuse</span>
                              </b-dropdown-item>

                              <b-dropdown-item v-if="$Can('dashboard_pending_timeoff')" @click="deleteTimeOff('R', data)">
                                <feather-icon icon="TrashIcon" />
                                <span class="align-middle ml-50">Delete</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </template>
                        </b-table>

                        <div class="mx-2 mb-2">
                          <b-row>
                            <!-- Pagination -->
                            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                              <b-pagination v-model="currentPage" :total-rows="timeOffData.length" :per-page="5" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                                <template #prev-text>
                                  <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                  <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                              </b-pagination>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                    </b-row>
                  </b-tab>

                  <b-tab title="Performance" v-if="$Can('user_performance_tab')">
                    <user-performance v-if="reportName != null" :report-user-name="reportName"></user-performance>
                  </b-tab>

                  <b-tab title="Tasks" v-if="$Can('user_tasks_tab')">
                    <user-tasks v-if="userInfo.name != null" :data="userInfo"></user-tasks>
                  </b-tab>

                  <b-tab title="Timeline" v-if="$Can('user_timeline_tab')">
                    <user-timeline v-if="userInfo.timeline != null" :data="userInfo.timeline"></user-timeline>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-modal id="modal-file" cancel-variant="outline-secondary" ok-title="Save File" @ok="handleOk" @show="resetModal" @hidden="resetModal" cancel-title="Close" :no-close-on-backdrop="true" centered title="Add New File">
        <b-form ref="fileRules">
          <b-form-group :state="nameState" invalid-feedback="File Name is required">
            <label for="fileName">File Name:</label>
            <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
          </b-form-group>
          <b-form-group>
            <b-form-checkbox class="custom-control-primary" name="check-button" switch inline v-model="selectFileType">
              <span class="switch-icon-left">
                <feather-icon icon="FileIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="LinkIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
          <b-form-group :state="fileState" invalid-feedback="File is required" v-if="selectFileType">
            <b-form-file @input="fileSelected" type="file" :state="fileState" required placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." class="animate__animated animate__fadeIn" />
          </b-form-group>
          <b-form-group :state="linkState" invalid-feedback="Link is required" v-else>
            <b-form-input :state="linkState" id="basicInput" v-model="fileLink" required placeholder="Enter Link" class="animate__animated animate__fadeIn" />
          </b-form-group>
        </b-form>
        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
        </b-col>
      </b-modal>

      <b-modal id="modal-file-update" cancel-variant="outline-secondary" ok-title="Change File" @ok="handleUpdate" cancel-title="Close" :no-close-on-backdrop="true" centered title="Add New File">
        <b-form ref="fileRules">
          <b-form-group :state="nameState" invalid-feedback="File Name is required">
            <label for="fileName">File Name:</label>
            <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
          </b-form-group>
        </b-form>
        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
        </b-col>
      </b-modal>

      <b-modal id="modal-time-off" cancel-variant="outline-secondary" ok-title="Schedule" @hidden="resetModalTimeOff" @ok="handleOkTimeOff" cancel-title="Close" :no-close-on-backdrop="true" centered :title="timeOffType + 'Time Off Request'">
        <b-form ref="timeOffRules">
          <b-form-group :state="timeOffDateState" invalid-feedback="Please select the dates" label-for="Time Off Dates">
            <validation-provider name="Time Off Dates" #default="{ errors }" rules="required">
              <flat-pickr placeholder="Time Off Dates" v-model="timeOffDate" class="form-control" :config="{ mode: 'multiple', dateFormat: 'd-m-Y' }" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </b-modal>
    </b-overlay>
  </section>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
('bootstrap-vue');
import { BModal, BAvatar, BCard, BCardBody, BRow, BCol, BImg, BLink, BButton, BAlert, BCardHeader, BCardTitle, VBToggle, BTabs, BTab, BCardText, BProgress, BTable } from 'bootstrap-vue';
import moment from 'moment';
import userStoreModule from '../userStoreModule';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, alphaNum, email } from '@validations';

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';

import ProfileCropper from '@core/components/image-cropper/ProfileCropper.vue';
import UserPerformance from './UserPerformance.vue';
import UserTasks from './UserTasks.vue';
import UserTimeline from './UserTimeline.vue';

// import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';

// import { avatarText } from '@core/utils/filter'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },

  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BButton,
    BAvatar,
    BModal,
    BCardBody,
    BImg,
    BCardHeader,
    BCardTitle,
    BTabs,
    BCardText,
    BTab,
    ProfileCropper,
    BProgress,
    vSelect,
    flatPickr,
    BTable,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    UserPerformance,
    UserTasks,
    UserTimeline,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'user';

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
  },

  data: function() {
    var userInfo = {
      timeline: [],

      profile: null,
      id: null,
      name: null,
      surname: null,
      email: null,
      role: null,
      birthday: null,
      phone: null,
      address: null,
      city: null,
      postcode: null,
    };

    var availabilityData = {
      startDate: null,
      endDate: null,
      availability: null,
    };

    const availabilityOptions = [
      { label: 'Holiday', value: 'H' },
      { label: 'Sick', value: 'S' },
      { label: 'AWOL', value: 'W' },
    ];
    return {
      userInfo: userInfo,
      reportName: null,
      imageUrl: store.state.app.profileURL + 'none.jpg',
      tempImage: null,
      profileURL: store.state.app.profileURL,
      selectedImage: null,
      croppedImage: null,

      percentCompleted: 0,
      currentPage: 1,

      onboarding_id: null,

      required,
      alphaNum,
      email,

      availabilityData: availabilityData,
      baseURL: store.state.app.baseURL,

      roleOptions: [],
      availabilityOptions,
      employeeId: null,
      formShow: false,
      permissions: [],
      users: [],

      availability: null,

      startDate: null,
      endDate: null,
      rangeDate: null,

      timeOffData: [],
      annualLeave: 0,
      sickLeave: 0,
      unpaidLeave: 0,

      //FILES
      where: router.currentRoute.name,
      files: [],
      selectFileType: true,
      fileLink: null,
      modalId: null,
      modalFileName: null,
      modalFile: null,
      download: false,
      fileURL: store.state.app.fileURL,
      percentCompleted: 0,

      fetchTeams: [],
      totalRows: 0,

      timeOffDate: null,
      timeOffType: null,

      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'm1',
      },

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },

      config: {
        dateFormat: 'd-m-Y',
        mode: 'range',
        locale: { firstDayOfWeek: 1 },
      },

      tableColumns: [
        { key: 'date', sortable: true, class: 'text-center' },
        { key: 'type', sortable: true, class: 'text-center' },
        { key: 'status', sortable: true, class: 'text-center' },
        { key: 'actions', sortable: true, class: 'text-center' },
      ],

      teamTableColumns: [
        { key: 'id', sortable: true, class: 'text-center' },
        { key: 'title', sortable: true, class: 'text-center' },
        { key: 'foreman', sortable: true, class: 'text-center' },
        { key: 'staff_count', sortable: true, class: 'text-center' },
        { key: 'day_rate', sortable: true, class: 'text-center' },
        { key: 'status', sortable: true, class: 'text-center' },
      ],
    };
  },

  created() {
    this.getEmployeeById();
  },

  methods: {
    editEmployee() {
      router.push({ name: 'user-edit', params: { id: router.currentRoute.params.id } });
    },

    deleteTimeOff(val, data) {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formShow = true;

          axiosIns
            .delete(`deleteTimeOff/${data.item.id}`)
            .then((res) => {
              this.getEmployeeById();
            })
            .catch((error) => {
              console.log('error delete ', error);
            });
        }
      });
    },

    approveTimeOff(data) {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Yes, approve it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formShow = true;

          axiosIns
            .post(`approveTimeOff`, { id: data.item.id })
            .then((res) => {
              this.getEmployeeById();
            })
            .catch((error) => {
              console.log('error delete ', error);
            });
        }
      });
    },

    refuseTimeOff(data) {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, refuse it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formShow = true;

          axiosIns
            .post(`refuseTimeOff`, { id: data.item.id })
            .then((res) => {
              this.getEmployeeById();
            })
            .catch((error) => {
              console.log('error delete ', error);
            });
        }
      });
    },

    clearDate() {
      if (this.rangeDate != null && this.rangeDate != '') {
        this.startDate = null;
        this.endDate = null;
        this.rangeDate = null;
      }
    },

    activateEmployee() {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Yes, activate the employee!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var tempData = {
            status: 'A',
            userId: router.currentRoute.params.id,
          };
          this.userInfo.status = 'active';

          store.dispatch('user/changeStatus', tempData).then((response) => {
            if (response.status == 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ User Update Successful',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
              this.getEmployeeById();
            }
          });
        }
      });
    },

    terminateEmployee() {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, deactivate the employee!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var tempData = {
            status: 'P',
            userId: router.currentRoute.params.id,
          };
          this.userInfo.status = 'passive';

          store.dispatch('user/changeStatus', tempData).then((response) => {
            if (response.status == 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ User Update Successful',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
              this.getEmployeeById();
            }
          });
        }
      });
    },

    removeEmployee() {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove the employee!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var tempData = {
            status: 'D',
            userId: router.currentRoute.params.id,
          };

          this.userInfo.status = 'deleted';

          store.dispatch('user/changeStatus', tempData).then((response) => {
            if (response.status == 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ User Update Successful',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
              this.getEmployeeById();
            }
          });
        }
      });
    },

    getEmployeeById() {
      this.formShow = true;

      if (router.currentRoute.params.id) {
        this.employeeId = parseInt(router.currentRoute.params.id);

        store
          .dispatch('user/fetchUser', { id: router.currentRoute.params.id })
          .then((response) => {
            this.userInfo = response.data;
            this.timeOffData = response.data.timeOffData;

            this.timeOffData.forEach((element) => {
              const specificDateParts = element.date.split('-');
              const currentYear = new Date().getFullYear();
              const isSameYear = parseInt(specificDateParts[2]) == parseInt(currentYear);

              if (element.type == 'Annual Leave' && element.status == 'B' && isSameYear) {
                this.annualLeave += 1;
              } else if (element.type == 'Sick Leave' && element.status == 'B' && isSameYear) {
                this.sickLeave += 1;
              } else if (element.type == 'Unpaid Leave' && element.status == 'B' && isSameYear) {
                this.unpaidLeave += 1;
              }
            });
            // this.fetchTeams = response.data.fetchTeams;
            this.totalRows = this.fetchTeams.length;
            this.files = response.data.get_files;
            this.reportName = this.userInfo.name + ' ' + this.userInfo.surname;
            this.users = response.data.userList;
            this.imageUrl = this.profileURL + response.data.profile;

            this.formShow = false;
          })
          .catch((error) => {
            router.push({ name: 'error-404' });
          });
      } else {
        this.formShow = false;
      }
    },

    dateFormat(val) {
      if (val != null) {
        moment.locale('en-US');
        return moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY');
      } else {
        return 'N/A';
      }
    },

    onTeamRowSelected(item) {
      router.push({ name: 'team-edit', params: { id: item[0].id } });
    },

    fileEdit(data) {
      if (data.fileId != null) {
        this.modalId = data.fileId;
        this.modalFileName = data.fileName;
        this.$bvModal.show('modal-file-update');
      } else {
        this.modalId = data.id;
        this.modalFileName = data.fileName;
        this.$bvModal.show('modal-file-update');
      }
    },

    linkEdit(data) {
      if (data.fileId != null) {
        this.modalId = data.fileId;
        this.modalFileName = data.fileName;
        this.$bvModal.show('modal-file-update');
      } else {
        this.modalId = data.id;
        this.modalFileName = data.fileName;
        this.$bvModal.show('modal-file-update');
      }
    },

    deleteFile(data) {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formShow = true;

          if (data.fileId != null) {
            axiosIns
              .delete(`deleteOnboardingFile/${data.fileId}`)
              .then((res) => {
                var tempList = [];
                this.files.forEach((element) => {
                  if (element.id != data.id) {
                    tempList.push(element);
                  }
                });

                this.files = tempList;
                this.formShow = false;
                this.getEmployeeById();
              })
              .catch((error) => {
                console.log('error delete ', error);
              });
          } else {
            axiosIns
              .delete(`deleteUserFile/${data.id}`)
              .then((res) => {
                var tempList = [];
                this.files.forEach((element) => {
                  if (element.id != data.id) {
                    tempList.push(element);
                  }
                });

                this.files = tempList;
                this.formShow = false;
                this.getEmployeeById();
              })
              .catch((error) => {
                console.log('error delete ', error);
              });
          }
        }
      });
    },

    addNewFile(val = null) {
      if (val) {
        this.onboarding_id = val.id;
      }
      this.$bvModal.show('modal-file');
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      if (this.selectFileType) {
        if (this.percentCompleted == 100) {
          var data = {
            user_id: this.userInfo.id,
            fileName: this.modalFileName,
            fileLink: this.modalFile,
            status: 'A',
            onboarding_id: this.onboarding_id,
          };

          // Trigger submit handler
          this.modalOkFile(data);
        }
      } else {
        var data = {
          user_id: this.userInfo.id,
          fileName: this.modalFileName,
          fileLink: this.fileLink,
          status: 'B',
          onboarding_id: this.onboarding_id,
        };
        this.modalOkFile(data);
      }
    },

    handleUpdate(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.modalUpdate();
    },

    timeOffAdd(data) {
      this.timeOffType = data;

      this.$nextTick(() => {
        this.$bvModal.show('modal-time-off');
      });
    },

    handleOkTimeOff(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler

      this.modalTimeOff();
    },

    modalTimeOff(val) {
      if (!this.checkFormValidityTimeOff()) {
        return;
      }

      if (this.timeOffDate) {
        this.$nextTick(() => {
          this.$bvModal.hide('modal-time-off');
        });

        this.formShow = true;

        var data = {
          dates: this.timeOffDate,
          type: this.timeOffType,
          user_id: this.userInfo.id,
        };

        axiosIns
          .post('bookTimeOff', data)
          .then((response) => {
            this.timeOffDate = null;
            this.timeOffType = null;
            if (response.data.length > 0) {
              let text = 'The dates below are already booked for time-off.<br>';
              response.data.forEach((element) => {
                text += '<p>' + element[0].date + ' - ' + element[0].type + '</p>';
              });

              this.$swal({
                title: 'Warning',
                html: text,
                icon: 'warning',
                dangerMode: true,
                showCancelButton: false,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });
            }

            this.formShow = false;
            this.getEmployeeById();
          })
          .catch((error) => {
            this.formShow = false;
            console.log(error);
          });
      }
    },

    resetModalTimeOff() {
      this.timeOffDate = null;
      this.timeOffType = null;
    },

    resetModal() {
      this.modalFileName = null;
      this.modalFile = null;
    },

    modalOkFile(data) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-file');
      });

      this.formShow = true;
      axiosIns
        .post('saveUserFile', data)
        .then((response) => {
          this.files.push({
            id: response.data.id,
            fileName: response.data.fileName,
            fileLink: response.data.fileLink,
            status: response.data.status,
          });

          this.selectFileType = true;
          this.fileLink = null;
          this.modalFileName = null;
          this.modalFile = null;
          this.onboarding_id = null;
          this.formShow = false;
          this.getEmployeeById();
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    modalUpdate(val) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-file-update');
      });

      this.formShow = true;

      var data = {
        id: this.modalId,
        fileName: this.modalFileName,
        fileLink: this.modalFile,
      };

      axiosIns
        .put('updateUserFile', data)
        .then((response) => {
          this.modalFileName = null;
          this.modalFile = null;

          this.files.forEach((file) => {
            if (file.id == this.modalId) {
              file.fileName = response.data.fileName;
            }
          });

          this.formShow = false;
          this.getEmployeeById();
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    fileSelected(event) {
      //  this.formShow = true;
      let formData = new FormData();
      formData.append('file', event);

      const config = {
        onUploadProgress: function(progressEvent) {
          this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }.bind(this),
      };

      axiosIns
        .post('uploadUserFile', formData, config)
        .then((response) => {
          this.modalFile = response.data;

          // this.tempImage = null;
          // this.selectedImage = null;
          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    checkFormValidity() {
      const nameValid = this.$refs.fileRules.checkValidity();
      const fileValid = this.$refs.fileRules.checkValidity();

      return fileValid && nameValid;
    },

    checkFormValidityTimeOff() {
      const timeValid = this.$refs.timeOffRules.checkValidity();

      return timeValid;
    },

    formatPrice(value) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');

        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    updateOnboardingCompleted(task) {
      task.isCompleted = !task.isCompleted;
      this.loading = true;
      console.log(task);
      store
        .dispatch('project/updateOnboardingCompleted', {
          id: task.id,
          isCompleted: task.isCompleted,
          userId: this.userInfo.id,
        })
        .then((res) => {
          this.loading = false;

          const successText = task.isCompleted ? '✔️ Task has been marked as completed.' : '⭕ Task has been marked as not completed.';

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: successText,
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    toggleExpand(task) {
      task.isExpanded = !task.isExpanded;
    },
  },

  computed: {
    timeOffDateState() {
      return this.timeOffDate != null ? true : false;
    },

    nameState() {
      return this.modalFileName != null && this.modalFileName != '' ? true : false;
    },

    fileState() {
      return this.modalFile != null && this.modalFile != '' ? true : false;
    },
    linkState() {
      let expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
      let regex = new RegExp(expression);
      let link = this.fileLink;
      if (link != null) {
        if (link.match(regex)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.icon {
  stroke: black;
  fill: none;
  width: 36px;
  height: 36px;
  stroke-width: 0.6666666666666666;
}

.user-title {
  width: 100%;
  font-weight: bold;
}

.user-badge {
  width: 50%;
  align-content: center;
}
</style>

<style scoped>
.tab-class >>> .nav-item {
  position: relative;
  display: flex;
  justify-content: start;
}

.tab-class >>> .nav-vertical {
  padding-left: 0 !important;
}

.tab-class >>> .nav-pills .nav-link.active {
  width: 100%;
}
</style>

<style scoped>
.toCapitalFirst {
  text-transform: capitalize;
}

.modern-table {
  width: 50%;
}

.modern-table th {
  padding: 5px;
  font-weight: bold;
  color: #7f7f7f;
}

.modern-table td {
  padding: 5px;
}

.emoji {
  font-size: 12px;
  vertical-align: middle;
}

.item-price {
  font-weight: bold;
  color: #7f7f7f;
}
</style>

<style lang="scss" scoped>
span.activeMenu {
  font-weight: 700;
  color: black;
  padding-bottom: 2px;
  box-shadow: inset 0 -2px #6d6e6f;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.passiveMenu {
  font-weight: 400;
  color: #6d6e6f;
  padding-bottom: 2px;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.activeMenu:hover {
  background-color: #f9f8f8;
  padding: 5px 10px 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

span.passiveMenu:hover {
  background-color: #f9f8f8;
  box-shadow: inset 0 -2px #afabac;
  padding: 5px 10px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

.projectTitle {
  font-size: 1.75rem;
  font-weight: 400;
  transition: padding 0.3s;
  border: solid 1px transparent;
  border-radius: 5px;
}

.projectTitle:hover {
  border-color: #ccc;
  background-color: #f9f8f8;
  cursor: pointer;
}

.subTitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 500;
}

.person {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 5px;
  transition: border 0.3s;
  min-width: 120px;
  max-width: 220px;
}

.person:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  border-color: #ccc;
}

.description {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 1px transparent;
  transition: border 0.3s;
  border-radius: 5px;
}

.description:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-color: #ccc;
}

.profile-photo {
  width: 42px;
  height: 42px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: white;
}

.initials {
  text-transform: uppercase;
}

.person-details {
  flex-grow: 1;
}

.name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.position {
  color: #6d6e6f;
  margin-top: 10px;
}
.close-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;
  color: black;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  transition: opacity 0.3s;
}

.person:hover .close-icon {
  opacity: 1;
}

/* TO - DO   */

.todo-header {
  display: flex;
  background-color: #f5f5f5;
}

.header-cell {
  padding: 7px;
  border: 1px solid #e5e5e5;
  font-weight: bold;
}

.task-content {
  display: flex;
  width: 100%;
}

.subtask-content {
  display: flex;
  width: 100%;
  left: 30px;
}

.todo-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.add-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  background-color: #dddddd20;
}

.add-cell:hover {
  background-color: #dddddd40;
  cursor: pointer;
}

.todo-cell:last-child {
  border-right: 1px solid #e5e5e5;
}

.task-name {
  display: flex;
  align-items: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-checkbox {
  position: relative;
}

.custom-checkbox .checkbox-icon {
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
}

.custom-checkbox .checkbox-icon:not(.checked)::before {
  content: '\2713';
  font-size: 10px;
  color: #000;
}

.custom-checkbox .checkbox-icon.checked {
  background-color: #198754;
}

.custom-checkbox .checkbox-icon.checked::before {
  content: '\2713';
  font-size: 14px;
  color: #fff;
}

.no-results {
  text-align: center;
  padding: 20px;
  display: none;
}

.no-results.show {
  display: block;
}

.w-90 {
  width: 90%;
}

.w-47 {
  width: 47%;
}
.w-45 {
  width: 45%;
}

.w-42 {
  width: 42%;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

.w-37 {
  width: 37%;
}

.w-5 {
  width: 5%;
}

.w-4 {
  width: 4%;
}

.w-3 {
  width: 3%;
}
.w-8 {
  width: 8%;
}

.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}

.w-60 {
  width: 60%;
}

.hover-background:hover {
  background-color: #edeae9;
  cursor: pointer;
  position: relative;
}

.hover-background:hover::after {
  content: '\23F7';
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  color: #bbb;
}

.popoverMenu {
  cursor: pointer;
  padding: 0.5rem;
}

.popoverMenu:hover {
  background-color: rgba(197, 197, 197, 0.05);
  padding: 0.5rem;
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #description-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>
