// helpers.js

export function getAvatarText(value) {
  if (value) {
    const words = value.split(' ');
    const initials = words.map((word) => word.charAt(0).toUpperCase());
    return initials.join('');
  } else {
    return 'NA';
  }
}

export function formatDate(value) {
  if (!value) return value;
  const date = new Date(value);
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0');
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date); // Kısa ay adını alır
  const year = date
    .getFullYear()
    .toString()
    .slice(-2);
  return `${day} ${month}  ${year}`;
}

export function getTeamClass(team) {
  switch (team) {
    case 'Systems Tailor Made':
      return 'text-primary';
    case 'Tailor Made Excel':
      return 'text-success';
    case 'Group Tailor Made':
      return 'text-warning';
    default:
      return 'text-secondary';
  }
}
