<template>
    <b-card v-if="data ? data.length : 0 > 0" style="overflow-y:scroll; height:500px">
                  <app-timeline class="mt-2">
                    <app-timeline-item v-for="item in data" :key="item.id" :variant="varyant(item.status)" :icon="varicon(item.status)">
                      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                        <h6 v-html="item.title"></h6>
                        <small class="text-muted">{{ dateFormatTimeline(item.created_at) }}</small>
                      </div>
                      <small>{{ 'By ' + item.userNameSurname }} - {{ item.ipAddress }}</small>

                    </app-timeline-item>
                  </app-timeline>
                </b-card>
  </template>
  
  <script>
  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
  import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
  import {BCard } from 'bootstrap-vue';
  import moment from 'moment';

  export default {
    components: {
      AppTimeline,
      AppTimelineItem,
      BCard
    },
    
  props: {
    data: {
      type: Array,
      required: true,
    },
},
methods:{

  varyant(val) {
    if (val == 'A') {
      return 'success';
    } else if (val == 'B') {
      return 'info';
    } else if (val == 'C') {
      return 'danger';
    } else if (val == 'D') {
      return 'secondary';
    } else if (val == 'E') {
      return 'warning';
    } else if (val == 'F') {
      return 'primary';
    } else {
      return 'success';
    }
  },

  varicon(val) {
    if (val == 'A') {
      return 'SaveIcon';
    } else if (val == 'B') {
      return 'RefreshCwIcon';
    } else if (val == 'C') {
      return 'XIcon';
    } else if (val == 'D') {
      return 'AlertOctagonIcon';
    } else if (val == 'E') {
      return 'ShareIcon';
    } else if (val == 'F') {
      return 'PrinterIcon';
    } else {
      return 'success';
    }
  },

  dateFormatTimeline(val) {
    return moment(String(val)).format('DD-MM-YYYY HH:mm');
  },

},


  }
  
  </script>